<script>
import ContentBlockNewView from '@/views/contentBlock/ContentBlockNewView'
import NotifyService from '@/services/NotifyService'

export default {
  name: 'ContentBlockEditView',
  extends: ContentBlockNewView,
  data () {
    return {
      contentBlockIdIsDisabled: true
    }
  },
  methods: {
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }

      try {
        await this.$store.dispatch('contentBlock/update', this.contentBlock)

        const error = this.$store.getters['contentBlock/error']
        if (error) {
          NotifyService.setErrorMessage(error)
          return
        }

        NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
      } catch (error) {
        console.error(error)
      }
    }
  },
  created () {
    this.getContentBlock()
  }
}
</script>
